module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,400,500,700,900","Bebas Neue:400","Montserrat:300,400,500,600,700,800,900","Work Sans:100,100i,200,200i,300,400,500,600,700,800,900","Open Sans:100,100i,200,200i,300,400,400i,500,600,700,800,900","Lora:100,100i,200,200i,300,400,500,600,700,800,900","Cabin:400"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0HJ718MJBN"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
